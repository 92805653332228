/* eslint-disable no-undef */
import React from 'react';
import { withProps, compose } from 'recompose';
import styled, { keyframes } from 'styled-components';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';
import markerIcon from 'assets/icons/marker.svg';
import scienceIcon from 'assets/icons/Nauka.svg';
import sportIcon from 'assets/icons/Sport.svg';
import transportIcon from 'assets/icons/Transport.svg';
import restaurantsIcon from 'assets/icons/Restauracje.svg';
import shopsIcon from 'assets/icons/Sklep.svg';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const mapStyles = [
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200',
      },
      {
        saturation: -61.8,
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF',
      },
      {
        saturation: -13.200000000000003,
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
];

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.figure`
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid ${({ theme }) => theme.colors.green};
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s ease infinite;
`;

const MapLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);

const getIcon = type => {
  switch (type.toLowerCase()) {
    case 'transport: transport':
      return transportIcon;
    // case 'parki':
    //   return parksIcon;
    case 'school':
      return scienceIcon;
    case 'sport':
      return sportIcon;
    case 'restaurant':
      return restaurantsIcon;
    case 'shops':
      return shopsIcon;
    case 'investment':
      return markerIcon;
    default:
      return null;
  }
};

const getLocationTitle = type => {
  switch (type.toLowerCase()) {
    case 'transport: transport':
      return 'Przystanek autobusowy';
    // case 'parki':
    //   return 'Park';
    case 'school':
      return 'Szkoła';
    case 'sport':
      return 'Fitness';
    case 'restaurant':
      return 'Restauracja';
    case 'shops':
      return 'Sklep';
    case 'investment':
      return 'Kameralny Prokocim';
    default:
      return null;
  }
};

const MapContainer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAswkjqTgGz6s6TA7JsI-LB6ltHQlfLEQ4&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <MapLoader />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => {
  return (
    <GoogleMap
      defaultZoom={15}
      defaultCenter={{ lat: props.lat, lng: props.lng }}
      defaultOptions={{ styles: mapStyles }}
    >
      {props.locations.map(
        ({
          homepageLocationMapLat,
          homepageLocationMapLng,
          locMapLocationName,
          homepageLocationMapPlace,
          isMarkerShown,
        }) => (
          <React.Fragment
            key={`${homepageLocationMapLat} + ${homepageLocationMapLng}`}
          >
            {(isMarkerShown || homepageLocationMapPlace === 'investment') && (
              <Marker
                onMouseOver={() =>
                  props.setCurrentLocation(getLocationTitle(locMapLocationName))
                }
                onMouseOut={() => props.setCurrentLocation('')}
                zIndex={5}
                position={{
                  lat: Number(homepageLocationMapLat),
                  lng: Number(homepageLocationMapLng),
                }}
                title={getLocationTitle(locMapLocationName)}
                icon={{
                  url: getIcon(homepageLocationMapPlace),
                  anchor:
                    homepageLocationMapPlace === 'investment'
                      ? new google.maps.Point(30, 66)
                      : new google.maps.Point(24, 48),
                  scaledSize:
                    homepageLocationMapPlace === 'investment'
                      ? new google.maps.Size(60, 60)
                      : new google.maps.Size(48, 48),
                }}
              />
            )}
          </React.Fragment>
        )
      )}
    </GoogleMap>
  );
});

export default MapContainer;
