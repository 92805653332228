import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import emailIcon from 'assets/icons/email2.svg';
import phoneIcon from 'assets/icons/phone2.svg';
import PDFIcon from 'assets/icons/PDF.svg';
import excellentLogo from 'assets/icons/logo-excellent.svg';
import ScrollableSection from 'react-update-url-on-scroll';
import { NavigationContext } from '../../contexts/NavigationContext';

const StyledSection = styled(Section)`
  position: relative;
`;

const Wrapper = styled.article`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.gray};
  line-height: 2.2rem;

  ${({ $withMarginTop }) =>
    $withMarginTop &&
    css`
      margin-top: 16px;
    `}
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  ${({ theme }) => theme.mq.s} {
    min-height: 410px;
    min-width: 500px;
    max-width: 600px;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  ${({ theme }) => theme.mq.xl} {
    margin-top: -20px;
    margin-left: 60px;
    max-width: 640px;
  }
`;

const LogoWrapper = styled.div`
  width: 300px;
  margin: 0 0 0 -30px;
  ${({ theme }) => theme.mq.s} {
    margin: 0 0 10px -30px;
  }
`;

const BoldText = styled.div`
  margin: 25px 0 10px;
`;

const StyledColumn = styled(Column)`
  margin-top: 20px;
  margin-left: 0;
`;

const Detail = styled(Description)`
  display: flex;
  align-items: center;
  text-decoration: none;
  margin-top: 7px;
  span {
    margin-left: 8px;
  }
`;

const StyledDetail = styled(Detail)`
  color: #2cb2ff;
  transition: 0.3s;
  max-width: max-content;
  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen200};
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.lightBlue};
  margin-bottom: 20px;
  transition: 0.3s;
  text-decoration: none;
  max-width: max-content;
  span {
    transition: 0.3s;
    margin-left: 6px;
    color: #2cb2ff;

    &:hover {
      color: ${({ theme }) => theme.colors.lightGreen200};
    }
  }
  &:hover {
    color: ${({ theme }) => theme.colors.lightGreen200};
  }
`;

const About2 = ({
  image,
  description,
  contactHeading,
  contactTeam,
  fileLink,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <ScrollableSection name={`${initialSearchParam}#wykonczenia  `}>
      <StyledSection>
        <Content>
          <Wrapper>
            <ImageWrapper>
              <LazyImage src={image} alt="image" cover />
            </ImageWrapper>
            <Column>
              <LogoWrapper>
                <Icon src={excellentLogo} alt="logo excellent" full />
              </LogoWrapper>
              <Description dangerouslySetInnerHTML={{ __html: description }} />
              <BoldText>
                <b>Obejrzyj pełną ofertę:</b>
              </BoldText>
              <Link target="_blank" href={fileLink}>
                <Icon src={PDFIcon} alt="pdf icon" size={36} />
                <span>Zobacz ofertę</span>
              </Link>
              <Description>{contactHeading}</Description>
              {contactTeam.map(({ name, phone, email }) => (
                <StyledColumn>
                  <Description>{name}</Description>
                  <Detail as="a" href={`tel:${phone}`}>
                    <Icon src={phoneIcon} alt="phone" size={18} />
                    <span>
                      <b>{phone}</b>
                    </span>
                  </Detail>
                  <StyledDetail as="a" href={`mailto:${email}`}>
                    <Icon src={emailIcon} alt="email" size={18} />
                    <span>{email}</span>
                  </StyledDetail>
                </StyledColumn>
              ))}
              <Description $withMarginTop>
                Odwiedź showroom ul. Józefa Marcika 14C, Kraków
              </Description>
            </Column>
          </Wrapper>
        </Content>
      </StyledSection>
    </ScrollableSection>
  );
};

About2.propTypes = {
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  description: PropTypes.string.isRequired,
  contactHeading: PropTypes.string.isRequired,
  contactTeam: PropTypes.arrayOf(PropTypes.object).isRequired,
  fileLink: PropTypes.string.isRequired,
};

export default About2;
