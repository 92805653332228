/* eslint-disable no-undef */
import React from 'react';
import { withProps, compose } from 'recompose';
import styled, { keyframes } from 'styled-components';
import markerIcon from 'assets/icons/marker.svg';
import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker,
} from 'react-google-maps';

const spin = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`;

const mapStyles = [
  {
    featureType: 'road.highway',
    stylers: [
      {
        hue: '#FFC200',
      },
      {
        saturation: -61.8,
      },
      {
        lightness: 45.599999999999994,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'road.local',
    stylers: [
      {
        hue: '#FF0300',
      },
      {
        saturation: -100,
      },
      {
        lightness: 52,
      },
      {
        gamma: 1,
      },
    ],
  },
  {
    featureType: 'water',
    stylers: [
      {
        hue: '#0078FF',
      },
      {
        saturation: -13.200000000000003,
      },
      {
        lightness: 2.4000000000000057,
      },
      {
        gamma: 1,
      },
    ],
  },
];

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Loader = styled.figure`
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid ${({ theme }) => theme.colors.green};
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-radius: 50%;
  animation: ${spin} 1s ease infinite;
`;

const MapLoader = () => (
  <Wrapper>
    <Loader />
  </Wrapper>
);

const MapContainer = compose(
  withProps({
    googleMapURL:
      'https://maps.googleapis.com/maps/api/js?key=AIzaSyAswkjqTgGz6s6TA7JsI-LB6ltHQlfLEQ4&v=3.exp&libraries=geometry,drawing,places',
    loadingElement: <MapLoader />,
    containerElement: <div style={{ height: `100%` }} />,
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(props => (
  <GoogleMap
    defaultZoom={16}
    defaultCenter={{ lat: props.lat, lng: props.lng }}
    defaultOptions={{ styles: mapStyles }}
  >
    <Marker
      position={{
        lat: props.lat,
        lng: props.lng,
      }}
      title="Biuro sprzedaży"
      icon={{
        url: markerIcon,
        anchor: new google.maps.Point(38, 92),
        scaledSize: new google.maps.Size(50, 70),
      }}
    />
  </GoogleMap>
));

export default MapContainer;
