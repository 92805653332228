import axios from 'axios';
import slugify from 'slugify';

const getFloor = name => {
  if (name.includes('A.00')) {
    return '0';
  }
  if (name.includes('A.01')) {
    return '1';
  }
  if (name.includes('A.02')) {
    return '2';
  }
  if (name.includes('A.03')) {
    return '3';
  }
  if (name.includes('A.04')) {
    return '4';
  }
  if (name.includes('A.05')) {
    return '5';
  }
  if (name.includes('A.06')) {
    return '6';
  }
  if (name.includes('A.07')) {
    return '7';
  }
  if (name.includes('A.08')) {
    return '8';
  }
  if (name.includes('A.09')) {
    return '9';
  }
  if (name.includes('A.10')) {
    return '10';
  }
};

export const getFlatsFromAPI = (url, parser, investment) => async () => {
  try {
    const response = await axios.get(url);

    if (response.status !== 200) {
      throw new Error(
        `Error while getting flats from ${url}: ${response.status}`
      );
    }

    // @ts-ignore
    return response.data.response.Products.Product.map(parser);
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const parseFlat = flat => {
  const flatInvestment =
    flat.InvestmentTitle === 'Heroldów'
      ? 'Na Bielany'
      : flat.InvestmentTitle === 'Facimiech'
      ? 'Kameralny Prokocim'
      : flat.InvestmentTitle;
  const flatBuilding =
    flat.InvestmentTitle === 'Heroldów'
      ? ['A', 'B'].includes(flat.Stairway)
        ? 'A'
        : 'B'
      : flat.StageTitle[flat.StageTitle.length - 1];

  const tour3D =
    flat.ExtraData.Values && Array.isArray(flat.ExtraData.Values.Field)
      ? flat.ExtraData.Values.Field.find(
          ({ Key }) => Key.toLowerCase().replace(' ', '') === 'spacer3d'
        )
      : null;

  const isBusinessUnit = flat.ID_ProductKind === '2';
  const price = Math.floor(Number(flat.TotalBrutto));
  const isPriceVisible = Boolean(Number(flat.w_IsShowPrice));
  const promotionPrice = Math.floor(Number(flat.TotalBruttoPromotion));
  const isPromotion = Boolean(Number(flat.w_IsPromotion));
  const promotionViewType = flat.w_PromotionViewType;

  const floorTemporary = getFloor(flat.ProjectNumber);

  return {
    isBusinessUnit,
    city: flat.city,
    estate: flat.disctrict,
    flatID: flat.ID_Product,
    investmentID: flat.ID_Investment,
    investment: flatInvestment,
    name: flat.ProjectNumber,
    price,
    pricePromotion: promotionPrice,
    isPriceVisible,
    isPromotion,
    promotionViewType,
    area: Number(flat.Area).toFixed(2),
    floor: floorTemporary,
    rooms: flat.Rooms,
    balcony:
      (flat.TotalSupplementArea &&
        Number(flat.TotalSupplementArea).toFixed(2)) ||
      null,
    garden:
      (flat.TotalSupplementArea &&
        Number(flat.TotalSupplementArea).toFixed(2)) ||
      null, // TODO: check if it's garden or balcony or if garden has some other property
    possibleToBook: flat.isPossibleToBook !== '0',
    status: Number(flat.ID_ProductStatus),
    PDF: flat.pdf,
    building: flatBuilding,
    PNG: `https://matexicms.indigital.pl/flats/${slugify(flatInvestment, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    })}/PNG/42-${
      flat.StageTitle[flat.StageTitle.length - 1]
    }-${flat.ProjectNumber.replace(/F[1-9]./, '')}.png`,
    PNGFloor: `https://matexicms.indigital.pl/flats/${slugify(flatInvestment, {
      lower: true,
      remove: /[*+~.()'"!:@]/g,
    })}/PNG2/42-${
      flat.StageTitle[flat.StageTitle.length - 1]
    }-${flat.ProjectNumber.replace(/F[1-9]./, '')}.png`,
    PNG3D: null,
    PNG360: [],
    similarFlats: flat.layoutProjectNumbers
      ? flat.layoutProjectNumbers.map((layout, i) => ({
          floor:
            flat.layoutFloors[i].toLowerCase() === 'parter' ||
            flat.layoutFloors[i] === '0'
              ? '0'
              : flat.layoutFloors[i],
          flat: layout,
        }))
      : null,
    tour3D: tour3D ? `${tour3D.Value}` : null,
  };
};
