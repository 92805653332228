import React, { lazy, Suspense } from 'react';

const Browser = lazy(() => import('browser'));

const BrowserWrapper = () => (
  <>
    {typeof window !== `undefined` && (
      <Suspense fallback={<div style={{ height: '100vh' }} />}>
        <Browser />
      </Suspense>
    )}
  </>
);

export default BrowserWrapper;
