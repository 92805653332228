import styled from 'styled-components';

const Section = styled.section`
  padding: ${({ padding }) => padding || '20px 0'};
  ${({ theme }) => theme.mq.s} {
    padding: ${({ padding }) => padding || '40px 0'};
  }
  ${({ theme }) => theme.mq.lg} {
    padding: ${({ padding }) => padding || '60px 0'};
  }
`;

export default Section;
