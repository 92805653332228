import React from 'react';
import styled from 'styled-components';
import Content from 'components/shared/Content';
import AskForm from './AskForm';

const Wrapper = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.lightGreen300};
  margin: 70px 0 40px;

  ${({ theme }) => theme.mq.md} {
    margin: 80px 0 70px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin: 80px 0 0;
  }

  ${({ theme }) => theme.mq.xxl} {
    margin: 80px 0 0;
  }
`;

const SectionAnchor = styled.span`
  position: absolute;
  left: 0;
  top: -130px;
`;

const AskAboutFlat = () => {
  return (
    <Wrapper>
      <SectionAnchor id="form-top" />
      <Content>
        <AskForm />
      </Content>
    </Wrapper>
  );
};

export default AskAboutFlat;
