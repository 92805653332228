import React, { useContext } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import LazyImage from 'components/shared/LazyImage';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Button from 'components/shared/Button';
// import Icon from 'components/shared/Icon';
import Browser from 'components/Flats/BrowserWrapper';
import LazyLoad from 'react-lazyload';
import scrollToSection from 'utils/scrollToSection';
import ScrollableSection from 'react-update-url-on-scroll';
// import prokocimLogo from 'assets/icons/logo-prokocim-2.svg';
import { NavigationContext } from '../../contexts/NavigationContext';

const StyledSection = styled(Section)`
  padding-top: 0 !important;
  #search-3d-anchor {
    display: block;
    height: 0;
  }
  ${({ theme }) => theme.mq.md} {
    padding-bottom: 20px;
  }
`;

const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
  }
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 252px;
    background: #32912f;
    z-index: -1;
    @media (max-width: 767px) {
      height: 300px;
    }
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  margin-top: 60px;
  ${({ theme }) => theme.mq.md} {
    margin-top: 0;
    height: 525px;
    min-width: 500px;
    width: 50%;
  }

  @media (min-width: 1540px) {
    height: 535px;
  }

  @media (min-width: 1920px) {
    height: 545px;
  }

  @media (min-width: 2400px) {
    height: 555px;
  }
`;

const Heading = styled.h2`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 40px;
  @media (max-width: 767px) {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    font-size: 2.5rem;
    line-height: 2.8rem;
    margin-top: 10px;
  }
`;

const Description = styled.p`
  font-weight: ${({ theme }) => theme.light};
  padding: 20px 20px 25px 0;
  font-size: 2.1rem;
  line-height: 2.4rem;
  @media (max-width: 767px) {
    font-size: 1.9rem;
    line-height: 2.2rem;
  }
`;

const Column = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  max-width: 600px;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
  padding: 0 20px 40px;
  ${({ theme }) => theme.mq.md} {
    padding: 40px 0;
    margin-top: 100px;
    align-items: flex-start;
    text-align: left;
  }
`;

const ImageBottomWrapper = styled.div`
  position: relative;
  width: 100%;
`;

// const LogoWrapper = styled.div`
//   position: absolute;
//   top: 10px;
//   left: 5%;
//   width: clamp(50px, 20vw, 270px);
//   height: auto;
// `;

const StyledButton = styled(Button)`
  padding: 10px 20px 10px 40px;
  &:after {
    right: 0;
    border-top: 25px solid transparent;
    border-bottom: 25px solid transparent;
    border-left: 30px solid ${({ theme }) => theme.colors.lightGreen200};
    border-right: unset;
    transform: translateX(100%);
  }
`;

const Flats = ({ image, imageBottom, imageAlt, heading, description }) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <ScrollableSection name={`${initialSearchParam}#mieszkania`}>
      <StyledSection>
        <Wrapper>
          <ImageWrapper>
            <LazyImage src={image} alt={imageAlt} />
          </ImageWrapper>
          <Column>
            <Heading dangerouslySetInnerHTML={{ __html: heading }} />
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <StyledButton onClick={() => scrollToSection('#kontakt')}>
              Zapytaj
            </StyledButton>
          </Column>
        </Wrapper>
        <span id="search-3d-anchor" />
        <LazyLoad offset={500} height="100vh" once>
          <Browser />
        </LazyLoad>
        <Content>
          <ImageBottomWrapper>
            {/* <LogoWrapper>
              <Icon src={prokocimLogo} alt="prokocim logo" full />
            </LogoWrapper> */}
            <LazyImage src={imageBottom} alt={imageAlt} />
          </ImageBottomWrapper>
        </Content>
      </StyledSection>
    </ScrollableSection>
  );
};

Flats.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageBottom: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export default Flats;
