import styled from 'styled-components'

export const Wrapper = styled.button`
  display: flex;
  align-items: center;

  position: fixed;
  bottom: 40px;
  right: 0;
  padding: 0;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
  z-index: 11;
`

export const Circle = styled.div`
  border-radius: 50%;
  overflow: hidden;
`

export const CircleWrapper = styled(Circle)`
  width: 66px;
  height: 66px;
`
export const CircleOuter = styled(Circle)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: #3b996678;
`
export const CircleInner = styled(Circle)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 44px;
  height: 44px;
  background: #3b9966;
`
export const KvIcon = styled.img`
  width: 12px;
  transform: rotate(-90deg);
`

export const PromotionButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 12px 12px 20px;
  background: #f3f0ee;
  border: 1px solid #3b9966;
  border-radius: 99px 0 0 99px;
  font-family: 'Inter', sans-serif;

  span {
    &.label {
      font-size: 14px;
      font-weight: 700;
      font-family: 'Inter', sans-serif;
      line-height: 17px;
      color: ${({ theme }) => theme.colors.black};
      text-transform: uppercase;
    }

    &.counter {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      font-size: 12px;
      font-weight: 700;
      font-family: 'Inter', sans-serif;
      line-height: 12px;
      color: #ffffff;
      background: #3b9966;

      &.bigger {
        width: 24px;
        height: 24px;
      }
    }
  }
`
