import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import Button from 'components/shared/Button';
import LazyImage from 'components/shared/LazyImage';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';

const StyledSection = styled(Section)`
  position: relative;
  ${({ theme }) => theme.mq.lg} {
    padding: 0;
  }
`;

const ScrollAnchor = styled.span`
  position: absolute;
  top: 2px;
  left: 0;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0 0 10px;
  ${({ theme }) => theme.mq.md} {
    padding: 0;
  }
  ${({ theme }) => theme.mq.lg} {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
`;

const DescriptionWrapper = styled.div`
  width: 100%;

  ${({ theme }) => theme.mq.lg} {
    padding: 30px;
  }
`;

const Description = styled.div`
  width: 100%;
  color: #6d6e71;
  p + p {
    margin-top: 20px;
  }
`;

const ProspectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 0 0;

  ${Button} {
    max-width: unset;
    width: fit-content;
  }
`;

const ProspectHeading = styled.h4`
  margin-bottom: 20px;
  font-size: ${({ theme }) => theme.fontSize.md};
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.semiBold};

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 25px;
  }
`;

const ImageWrapper = styled.div`
  display: none;
  ${({ theme }) => theme.mq.lg} {
    display: block;
    position: relative;
    height: 100%;
  }
`;

const ImageInnerWrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colors.lightGreen200};
`;

const About = ({ image, content, prospect }) => {
  return (
    <StyledSection>
      <ScrollAnchor id="o-inwestycji" />
      <Content>
        <InnerWrapper>
          <DescriptionWrapper>
            <Description dangerouslySetInnerHTML={{ __html: content }} />
            {prospect.link && (
              <ProspectWrapper>
                <ProspectHeading>{prospect.title}</ProspectHeading>
                <Button
                  as="a"
                  href={prospect.link}
                  target="_blank"
                  rel="noreferrer"
                  onClick={() => {
                    emitMatomoEvent({
                      event: eventsNames.click_prospekt_informacyjny,
                    });
                  }}
                >
                  {prospect.buttonText}
                </Button>
              </ProspectWrapper>
            )}
          </DescriptionWrapper>
          <ImageWrapper>
            <ImageInnerWrapper>
              <LazyImage src={image} alt="happy couple hugging" cover auto />
            </ImageInnerWrapper>
          </ImageWrapper>
        </InnerWrapper>
      </Content>
    </StyledSection>
  );
};

About.propTypes = {
  content: PropTypes.string.isRequired,
  prospect: PropTypes.object,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
};

export default About;
