import React, { useState, useContext } from 'react';
import styled, { css } from 'styled-components';
import { NavigationContext } from 'contexts/NavigationContext';

import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css';

import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';

import arrowIcon from 'assets/icons/arrow-right.svg';
import useMedia from 'browser/hooks/useMedia';

import ScrollableSection from 'react-update-url-on-scroll';

const Section = styled.section`
  position: relative;
  width: 100%;
  padding-top: 2rem;

  ${({ theme }) => theme.mq.lg} {
    padding-top: 5rem;
  }

  ${({ theme }) => theme.mq.xxl} {
    padding-top: 8rem;
    padding-bottom: 3rem;
  }
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 15px;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: 30px;
  }
`;

const ControlButtons = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: calc(90%);
  z-index: 1;

  ${({ theme }) => theme.mq.lg} {
    display: none;
    width: calc(100% + 90px);
  }
`;

const ControlButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 50%;
  width: 35px;
  height: 35px;
  box-shadow: 1px 1px 10px 3px #00000094;

  :first-of-type {
    transform: rotate(180deg);
  }

  :last-of-type {
    margin-left: auto;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}
`;

const SliderWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 560px;
  margin-inline: auto;

  ${({ theme }) => theme.mq.lg} {
    position: relative;
    width: 80vw;
    max-width: 1760px;
    height: auto;
    overflow: hidden;

    .swiper-container {
      width: 100%;
      height: 340px;
      overflow: hidden;
    }

    .swiper-slide {
      text-align: center;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0px;
      background: white;
      transition: all 0.3s ease;
      cursor: grab;

      &.swiper-slide-active {
        color: #fff;
        background: transparent;
        transform: scale(1.55);
        z-index: 2;

        ${ControlButtons} {
          display: flex;
        }
      }
    }
  }

  ${({ theme }) => theme.mq.xxl} {
    .swiper-container {
      height: 400px;
    }
  }

  @media (min-width: 1600px) {
    width: 85vw;

    .swiper-container {
      height: 450px;
    }
  }
`;

const ConstructionProgressGallery = ({ heading, slides }) => {
  const [swiper, setSwiper] = useState(null);
  const [activeSlideIndex, setActiveSlideIndex] = useState(1);

  const { initialSearchParam } = useContext(NavigationContext);

  const matchesLG = useMedia('lg');

  const goPrev = () => {
    swiper?.slidePrev();
  };

  const goNext = () => {
    swiper?.slideNext();
  };

  return typeof window !== 'undefined' ? (
    <ScrollableSection name={`${initialSearchParam}#gotowe-etapy`}>
      <Section title="Gotowe etapy" id="gotowe-etapy">
        <Heading dangerouslySetInnerHTML={{ __html: heading }} />
        <SliderWrapper>
          <Swiper
            slidesPerView={matchesLG ? 3 : 1}
            onSwiper={initSwiper => setSwiper(initSwiper)}
            onSlideChange={swiperInstance =>
              setActiveSlideIndex(swiperInstance.realIndex)
            }
            speed={1000}
            spaceBetween={20}
            centeredSlides
            initialSlide={matchesLG ? 1 : 0}
          >
            {slides.map(({ img, alt }, index) => (
              <SwiperSlide key={alt + index}>
                <LazyImage src={img} alt={alt} />
                {matchesLG && (
                  <ControlButtons>
                    <ControlButton
                      onClick={goPrev}
                      hidden={activeSlideIndex === 0}
                    >
                      <Icon src={arrowIcon} size={12} alt="arrow-btn" />
                    </ControlButton>
                    <ControlButton
                      onClick={goNext}
                      hidden={activeSlideIndex === slides.length - 1}
                    >
                      <Icon src={arrowIcon} size={12} alt="arrow-btn" />
                    </ControlButton>
                  </ControlButtons>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
          {!matchesLG && (
            <ControlButtons>
              <ControlButton onClick={goPrev}>
                <Icon src={arrowIcon} size={10} alt="arrow-btn" />
              </ControlButton>
              <ControlButton onClick={goNext}>
                <Icon src={arrowIcon} size={10} alt="arrow-btn" />
              </ControlButton>
            </ControlButtons>
          )}
        </SliderWrapper>
      </Section>
    </ScrollableSection>
  ) : null;
};

export default ConstructionProgressGallery;
