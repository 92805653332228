/* eslint-disable react/no-unescaped-entities */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
import ContactForm from 'components/Contact/ContactForm';
import ContactMap from 'components/Contact/ContactMap';
import Icon from 'components/shared/Icon';
import LazyLoad from 'react-lazyload';
import phoneIcon from 'assets/icons/phone.svg';
import ScrollableSection from 'react-update-url-on-scroll';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';
import { NavigationContext } from '../../contexts/NavigationContext';

const StyledSection = styled(Section)`
  padding-bottom: 40px !important;
  background: ${({ theme }) => theme.colors.lightGreen200};
`;

const SectionInnerWrapper = styled.div`
  padding: 20px 0 0;
  ${({ theme }) => theme.mq.xl} {
    padding: 20px 0 70px;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  ${({ theme }) => theme.mq.lg} {
    grid-template-columns: repeat(2, 1fr);
    justify-content: space-between;
  }
`;

const ContactAddress = styled.address`
  position: relative;
  display: flex;
  flex-direction: column;
  font-weight: ${({ theme }) => theme.light};
  background: ${({ theme }) => theme.colors.green};
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.lg} {
    max-width: 700px;
  }
`;

const Padding = styled.div`
  padding: 30px 15px 30px;
`;

const StyledPadding = styled(Padding)`
  ${({ theme }) => theme.mq.lg} {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
`;

const Title = styled.h2`
  font-size: 2.2rem;
  line-height: 2.8rem;
  text-align: center;
  ${({ theme }) => theme.mq.xs} {
    font-size: 2.6rem;
    line-height: 3.4rem;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 250px;
  ${({ theme }) => theme.mq.s} {
    height: 400px;
  }
`;

const Column = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 230px;
  margin: 0 auto;
  text-align: center;
  gap: 4px 10px;
  ${({ theme }) => theme.mq.xs} {
    max-width: 250px;
  }
  & > * {
    font-size: 1.9rem;
    font-weight: ${({ theme }) => theme.light};
  }
`;

const Time = styled.time`
  position: relative;
  ${({ tooltip }) =>
    tooltip &&
    css`
      em {
        position: absolute;
        bottom: 4px;
        right: 0;
        background: #fff;
        color: #111;
        padding: 3px;
        border-radius: 4px;
        border: 1px solid ${({ theme }) => theme.colors.gray200};
        font-size: 1.2rem;
        transition: 0.3s;
        opacity: 0;
        visibility: hidden;
        transform: translateX(15px);
        white-space: nowrap;
      }
      &:hover > em {
        opacity: 1;
        visibility: visible;
        transform: translateX(0);
      }
      &:after {
        content: '?';
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.3rem;
        bottom: 6px;
        right: -27px;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        color: #fff;
        border: 1px solid #fff;
      }
    `}
`;

const Detail = styled.p`
  font-size: 1.9rem;
  font-weight: ${({ theme }) => theme.light};
  padding-bottom: 15px;
  text-align: center;
`;

const PDFButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  background: ${({ theme }) => theme.colors.green};
  transition: 0.3s;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  padding: 12px 40px;
  &:hover {
    background: ${({ theme }) => theme.colors.lightGreen100};
  }
  margin-top: 30px;
  ${({ theme }) => theme.mq.lg} {
    margin-top: auto;
  }
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
`;

const Info = styled.div`
  padding: 20px 0;
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: normal;
  color: ${({ theme }) => theme.colors.white};
  p {
    margin: 10px 0;
  }
  ${({ theme }) => theme.mq.s} {
    padding: 20px 0 10px;
    font-size: 1.8rem;
  }
`;

const Phone = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fonts.specialFont};
  text-decoration: none;
  font-size: 4rem;
  margin-top: 25px;
  span {
    margin-left: 10px;
    transform: rotate(-5deg) translateY(-10px);
  }
  ${({ theme }) => theme.mq.s} {
    font-size: 6.4rem;
  }
`;

const PhoneIcon = styled.div`
  width: 35px;
  height: 35px;
  transform: translateY(5px);
  ${({ theme }) => theme.mq.s} {
    width: 50px;
    height: 50px;
  }

  ${({ theme }) => theme.mq.md} {
    transform: translateY(10px);
  }
`;

const Contact = ({
  mapCords,
  title,
  address,
  phone,
  openingHours,
  announcement,
  pdf,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <ScrollableSection name={`${initialSearchParam}#kontakt`}>
      <StyledSection>
        <SectionInnerWrapper>
          <Content>
            <Wrapper>
              <LeftColumn>
                <ContactForm />
                {pdf.link && pdf.text && (
                  <PDFButton
                    href={pdf.link}
                    target="_blank"
                    dangerouslySetInnerHTML={{
                      __html: pdf.text,
                    }}
                  />
                )}
              </LeftColumn>
              <ContactAddress>
                <Padding>
                  <Title>{title}</Title>
                  <Title>{address}</Title>
                  {/* <Description dangerouslySetInnerHTML={{ __html: content }} /> */}
                </Padding>
                <LazyLoad height={400} offset={500} once>
                  <MapWrapper>
                    <ContactMap lat={mapCords.lat} lng={mapCords.lng} />
                  </MapWrapper>
                </LazyLoad>
                <StyledPadding>
                  <Detail>Godziny otwarcia:</Detail>
                  <Column>
                    {openingHours.map(({ day, openingTime, closingTime }) => (
                      <React.Fragment key={day}>
                        <span>{day}</span>
                        <time>{openingTime}</time>
                        <span>{closingTime && '-'}</span>
                        <Time>{closingTime}</Time>
                      </React.Fragment>
                    ))}
                  </Column>
                  {announcement && (
                    <Info dangerouslySetInnerHTML={{ __html: announcement }} />
                  )}
                  <Phone
                    href={`tel:${phone}`}
                    onClick={() =>
                      emitMatomoEvent({
                        event: eventsNames.click_telefon,
                        tel: phone,
                      })
                    }
                  >
                    <PhoneIcon>
                      <Icon full src={phoneIcon} alt="phone" />
                    </PhoneIcon>
                    <span> {phone}</span>
                  </Phone>
                </StyledPadding>
              </ContactAddress>
            </Wrapper>
          </Content>
        </SectionInnerWrapper>
      </StyledSection>
    </ScrollableSection>
  );
};

Contact.propTypes = {
  title: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  pdf: PropTypes.objectOf(PropTypes.string).isRequired,
  mapCords: PropTypes.objectOf(PropTypes.number).isRequired,
  openingHours: PropTypes.arrayOf(PropTypes.object).isRequired,
  announcement: PropTypes.string.isRequired,
};

export default Contact;
