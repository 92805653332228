const getInvestmentID = investmentName => {
  switch (investmentName) {
    case 'Kameralny Prokocim':
      return '35';
    case 'Kameralny Prokocim 2':
      return '46';
    case 'Grzybowska 37':
      return '25';
    case 'Rogalskiego':
      return '30';
    case 'Na Bielany':
      return '26';
    case 'Żeromskiego 17':
      return '18';
    case 'Omulewska 26':
      return '18';
    case 'Świtezianki':
      return '34';
    default:
      return null;
  }
};

const getParamKey = key => {
  switch (key.toLowerCase()) {
    case 'name':
      return 'firstname';
    case 'surname':
      return 'lastname';
    case 'form_name':
      return 'form_name';
    case 'email':
      return 'email';
    case 'phone':
      return 'phone';
    case 'message':
      return 'message';
    case 'area':
      return 'area';
    case 'rooms':
      return 'rooms';
    case 'url':
      return 'url';
    case 'flatid':
      return 'id_product';
    default:
      return null;
  }
};

const getFormParams = params => {
  const formParams = { accepts: '', key: '585ee263d5148ce97aa833f48724be1f' };

  Object.keys(params).forEach(param => {
    const key = getParamKey(param);
    const keyValue = params[param];

    if (param.includes('agree') && !param.includes('content') && keyValue) {
      formParams.accepts += param.replace('agree', '');
    } else if (param === 'investment') {
      formParams.id_investment = getInvestmentID(keyValue);
    } else if (key) {
      formParams[key] = keyValue;
    }
  });
  formParams.accepts = formParams.accepts.split('').join(',');

  return formParams;
};

export default getFormParams;
