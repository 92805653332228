import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Content from 'components/shared/Content';
// import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
// import notusLogo from 'assets/icons/notus.svg';
import kvDetails from 'assets/icons/details-kv.svg';
import mFinanse from 'assets/icons/mfinanse.svg';
import ScrollableSection from 'react-update-url-on-scroll';
import { NavigationContext } from '../../contexts/NavigationContext';

// const StyledSection = styled(Section)`
//   padding: 0 0 20px !important;
// `;

const CreditSection = styled(Section)`
  background: ${({ theme }) => theme.colors.lightBlue};
`;

const Wrapper = styled.div`
  max-width: 1080px;
  margin: 0 auto;
`;

const Heading = styled.h2`
  font-size: 2.3rem;
  line-height: normal;
  font-weight: ${({ theme }) => theme.light};
  color: ${({ theme }) => theme.colors.green};
  margin-bottom: 15px;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
  }
`;

const Description = styled.div`
  font-weight: ${({ theme }) => theme.light};
  padding: 20px 0 15px;
  line-height: 2.2rem;
  text-align: justify;

  li {
    display: flex;
    margin-bottom: 5px;

    :first-child {
      margin-top: 10px;
    }

    &:before {
      content: '';
      display: block;
      width: 5px;
      min-width: 5px;
      height: 5px;
      min-height: 5px;
      border-radius: 50%;
      margin-right: 6px;
      background-color: #000000;
      position: relative;
      top: 7px;
    }
  }

  ${({ theme }) => theme.mq.lg} {
    li {
      margin-bottom: 6px;

      &:before {
        width: 6px;
        min-width: 6px;
        height: 6px;
        min-height: 6px;
        margin-right: 8px;
      }
    }
  }
`;

const Detail = styled(Description)`
  padding: 0;
  text-align: left;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    margin-top: 20px;
  }
`;

const TeamMember = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 10px 0;
`;

const TeamIcon = styled.div`
  display: none;
  margin: 0 20px 0 0;
  ${({ theme }) => theme.mq.xs} {
    display: block;
  }
`;

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  & > a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.gray};
    margin-right: 10px;
    span {
      color: ${({ theme }) => theme.colors.green};
    }
  }
`;

const Text = styled.p``;

const Text1 = styled(Text)`
  margin-top: 15px;
  margin-bottom: 10px;
`;
const Text2 = styled(Text)`
  font-style: italic;
  margin-top: 10px;

  max-width: 700px;
`;
const OpeningHoursLabels = styled.div`
  display: grid;
  row-gap: 3px;
`;

const Logo = styled.div`
  width: 110px;
  margin-bottom: 35px;

  ${({ theme }) => theme.mq.lg} {
    width: 130px;
  }
`;

// const ImageWrapper = styled.div`
//   width: 100%;
//   max-width: 240px;
//   height: 90px;
//   margin-bottom: 15px;
//   ${({ theme }) => theme.mq.xs} {
//     margin-top: 20px;
//   }
//   ${({ theme }) => theme.mq.md} {
//     margin-bottom: 0;
//     margin-right: 15px;
//   }
//   ${({ theme }) => theme.mq.lg} {
//     margin-right: 45px;
//   }
// `;

const KV = styled.div`
  display: none;

  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(-50%, 100%);

  ${({ theme }) => theme.mq.lg} {
    display: block;
    width: 220px;
  }
`;

const Credit = ({
  heading,
  description,
  teamHeading,
  team,
  text1,
  text2,
  openingHoursLabels,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <ScrollableSection name={`${initialSearchParam}#kredyt`}>
      {/* <StyledSection>
        <Content>
          <LazyImage src={image} alt="couple" />
        </Content>
      </StyledSection> */}
      <CreditSection>
        <Content>
          <Wrapper>
            <Logo>
              <Icon src={mFinanse} alt="mFinanse logo" full />
            </Logo>
            <Heading>{heading}</Heading>
            <Description dangerouslySetInnerHTML={{ __html: description }} />
            <Description dangerouslySetInnerHTML={{ __html: teamHeading }} />
            <FlexWrapper>
              {/* <ImageWrapper>
                <Icon src={notusLogo} alt="notus logo" full />
              </ImageWrapper> */}
              {team.length > 0 && (
                <InnerWrapper>
                  {team.map(({ icon, name, job, phone, email }) => (
                    <TeamMember key={name}>
                      <TeamIcon>
                        <Icon size={44} src={icon} alt="person icon" />
                      </TeamIcon>
                      <InnerWrapper>
                        <Detail>
                          <b>{name}</b> - {job}
                        </Detail>
                        <Flex>
                          <Detail as="a" href={`tel:${phone}`}>
                            Tel.: {phone}
                          </Detail>
                          <Detail as="a" href={`mailto:${email}`}>
                            email: <span>{email}</span>
                          </Detail>
                        </Flex>
                      </InnerWrapper>
                    </TeamMember>
                  ))}
                  {text1 && (
                    <Text1
                      size={16}
                      weight={700}
                      dangerouslySetInnerHTML={{ __html: text1 }}
                    />
                  )}

                  {openingHoursLabels && openingHoursLabels.length > 0 && (
                    <OpeningHoursLabels>
                      {openingHoursLabels.map(label => (
                        <Text
                          size={16}
                          key={label}
                          dangerouslySetInnerHTML={{ __html: label }}
                        />
                      ))}
                    </OpeningHoursLabels>
                  )}
                  {text2 && (
                    <Text2
                      size={16}
                      dangerouslySetInnerHTML={{ __html: text2 }}
                    />
                  )}
                </InnerWrapper>
              )}
            </FlexWrapper>
          </Wrapper>
          <KV aria-hidden>
            <Icon src={kvDetails} alt="key visual" full />
          </KV>
        </Content>
      </CreditSection>
    </ScrollableSection>
  );
};

Credit.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  teamHeading: PropTypes.string.isRequired,
  team: PropTypes.arrayOf(PropTypes.object).isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  openingHoursLabels: PropTypes.arrayOf(PropTypes.string).isRequired,
  // image: PropTypes.objectOf(
  //   PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  // ).isRequired,
};

export default Credit;
