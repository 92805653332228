import React, { useContext } from 'react';
import { NavigationContext } from 'contexts/NavigationContext';

import kvImageWhiteIcon from 'assets/icons/kvImageWhite.svg';

import {
  CircleInner,
  CircleOuter,
  CircleWrapper,
  KvIcon,
  PromotionButton,
  Wrapper,
} from './ButtonPromotion.style';

export const ButtonPromotion = ({ count }) => {
  const { openPromotionDrawer } = useContext(NavigationContext);

  return (
    <Wrapper type="button" onClick={openPromotionDrawer}>
      <CircleWrapper>
        <CircleOuter>
          <CircleInner>
            <KvIcon
              src={kvImageWhiteIcon}
              alt={count > 1 ? 'promocje' : 'promocja'}
            />
          </CircleInner>
        </CircleOuter>
      </CircleWrapper>
      <PromotionButton as="div">
        <span className="label">{count > 1 ? 'promocje' : 'promocja'}</span>
        <span className={`counter ${count > 9 ? 'bigger' : ''}`}>{count}</span>
      </PromotionButton>
    </Wrapper>
  );
};
